<template>
    <div style="padding:10px;">
        <a-tabs default-active-key="1">
            <a-tab-pane key="1" tab="账户信息">
               <a-row>
                  <a-col :span="24">
                     <a-select v-model="jumpto" style="width:200px" placeholder="选择文献网址">
                      <a-select-option v-for="item in accounts" :value="item.url" :key="item.url">{{item.name}}</a-select-option>
                     </a-select>
                  </a-col>
                  <a-col :span="24" style="padding:20px">
                    <a-button type="primary" @click="goto">跳转登录</a-button>
                  </a-col>
               </a-row>

            </a-tab-pane>
            <a-tab-pane key="2" tab="公告栏">
              <salarynoticelist ref="notelist" noticetype="documentsearch"/>
            </a-tab-pane>
            <template slot="tabBarExtraContent">
              <p-button type="default" @click="showAddNotice" :roles="['文献查询-发布公告']" name="发布公告"/>


            </template>

          </a-tabs>
          <a-modal
            title="公告信息"
            v-model="noticemodalvisible"
            width="90%"
            :footer="false"
            destroyOnClose
            :maskClosable="false"
          >
            <salarynotice @callback="modelCallBack" noticetype="documentsearch"/>
          </a-modal>
    </div>
</template>
<script>
import salarynotice from "./salarynotice"
import salarynoticelist from "./salarynoticelist"
import searchapi from "@/api/personal/searchaccount"
export default {
    name:"salary",
    components:{salarynotice,salarynoticelist},
    data(){
      return {
        noticemodalvisible:false,
        accounts:[],
        jumpto:""
      }
    },
    mounted(){
      this.getaccounts();
    },
    methods:{
      goto(){
        if(this.jumpto!=''){
          window.open(this.jumpto)
        }
      },
      getaccounts(){
        searchapi.getData().then(res=>{
          if (res.errorCode == this.$commons.RespCode.success) {
              this.accounts=res.data;
          }
        })
      },
      showAddNotice(){
        this.noticemodalvisible=true;
      },
      modelCallBack(){
        this.noticemodalvisible=false;
        this.$refs.notelist.GetList();
      }
    }
}
</script>
